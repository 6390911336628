import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
import { useState, useEffect } from 'react';
const PreLoader = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  }, []);
  return (
    <div className="w-full h-full flex items-center justify-center absolute top-0 right-0 left-0 md:left-24 bottom-0 ">
      {/* <Spinner className="animate-spin h-16 w-16 text-primary" /> */}
      <img src="\img\Loader\Hominifi_Loading.gif" alt="" width={170} />
    </div>
  );
};

export default PreLoader;
