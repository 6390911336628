import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthHeader from './AuthHeader';
import PrelineSetup from '../PrelineSetup';
import { useSelector } from 'react-redux';
import { selectUserSlice } from '../../store/user/userSlice';
import { PAGES } from '../../utils/routers';
import { extractSSOParams } from '../../utils/helper';
export default function AuthLayout(): JSX.Element {
  const navigate = useNavigate();
  const { user } = useSelector(selectUserSlice);
  useEffect(() => {
    if (user) {
      const ssoParams = localStorage.getItem('ssoUrl');
      if (ssoParams) {
        let params: any = extractSSOParams(ssoParams?.toString() as string);
        const { redirect_uri, scope, response_type, client_id } = params;
        if (redirect_uri && scope && response_type && client_id) {
          return navigate('/sso' + ssoParams, {
            replace: true,
          });
        }
      }
      navigate(PAGES.DASHBOARD);
    }
  }, [user]);
  return (
    <PrelineSetup>
      <div className="flex flex-col gap-4 max-w-[1200px] sm:px-8 px-4 items-center m-auto">
        <AuthHeader />
        <div className="flex justify-between w-full md:gap-32 lg:gap-48 py-14 p-0  items-center">
          <Outlet />
        </div>
      </div>
    </PrelineSetup>
  );
}
