export const homnifiLogo = '/img/Logo/homnifiLogo.svg';
export const profileImg = '/img/Profile/profile.jpg';
export const homnifiStake = '/img/Stake/homnifiStake.png';
export const homnifiMachine = '/img/Stake/machine.png';
export const horysmallStake = '/img/Stake/HorysmallStake.png';
export const SmStake = '/img/Stake/SmStake.png';
export const USDKPromoStake = '/img/Stake/USDKPromoStake.png';
export const editIcon = '/img/icons/edit.svg';
export const dummyUser = '/img/Profile/user1.png';
export const emptyBox = '/img/empty-box.png';
export const dummyUserLarge = '/img/Profile/dummyUserLarge.png';
export const bestValue = '/img/best-value.png';
export const tickIcon = '/img/tick-icon.png';
export const cloudLykLogo = '/img/cloud/LYK 1.png';
