import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISNGPPool } from '../../types/sngpPool.type';

const initialState: ISNGPPool = {
  activityLoading: false,
  activity: {
    claimed: 0,
    balance: 0,
  },
  totalSNGPLoading: false,
  totalSngp: {
    totalSngp: 0,
  },
  totalClaimed: {
    globalSngpUsed: 0,
  },
  sngpRecords: [],
  userSNGPCommunityLoading: false,
  userSNGPCommunity: [],
  historyLoading: false,
  history: {
    totalCount: 0,
    totalPages: 0,
    list: [],
    currentPage: 0,
  },
  sngpRecordsLoading: false,
};

export const SNGP_SLICE_NAME = 'sngpPool';
const sngpPoolSlice = createSlice({
  name: SNGP_SLICE_NAME,
  initialState,
  reducers: {
    setSNGPPoolSlice: (state, action: PayloadAction<Partial<ISNGPPool>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSNGPPoolSlice } = sngpPoolSlice.actions;

export const selectSNGPPoolSlice = (state: { [SNGP_SLICE_NAME]: ISNGPPool }) =>
  state[SNGP_SLICE_NAME];

export default sngpPoolSlice.reducer;
