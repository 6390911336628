//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

const name = 'walletData';

export type TPaginated = {
  totalPages: number;
  currentPage: number;
  totalCount: number;
};

export type TWithdrawAndTransferHistory = {
  list: {
    _id: string;
    id: number;
    time: string;
    type: string;
    withdrawToken: string;
    amount: number;
    destination: string;
    status: string;
    requestId: string;
  }[];
} & TPaginated;

export type TDepositHistory = {
  list: {
    id: string;
    time: string;
    type: string;
    amount: number;
    hash: string;
    status: string;
  }[];
} & TPaginated;

export type TSwapHistory = {
  list: {
    requestId: string;
    type: string;
    time: string;
    fromToken: string;
    toToken: string;
    amount: string;
    status: string;
  }[];
} & TPaginated;
// Define the type for the Network object
interface Network {
  _id: string;
  name: string;
  code: string;
  createdAt: string;
  updatedAt: string;
}

// Define the type for transaction settings
interface TrxSettings {
  swapEnabled: boolean;
  depositEnabled: boolean;
  withdrawEnabled: boolean;
}

// Define the type for the Token object
interface Token {
  name: string;
  symbol: string;
  color: string;
  iconUrl: string;
  networkList: Network[];
  trxSettings?: TrxSettings;
  valueType?: string;
}

// Define the type for the main object
export interface TokenData {
  balance: number;
  percentage: string;
  totalStaked?: number;
  _id: string;
  token: Token;
  claimableReward?: number;
  totalAvailableToStake?: number;
  dollarBalance?: number | undefined;
  valueType?: string;
}
export interface TokenInfo {
  token: string;
  balance: number;
  color: string;
  borderColor: string;
  iconUrl: string;
  trxSettings: TrxSettings;
  totalStaked?: number;
  claimableReward?: number;
  totalAvailableToStake?: number;
  tokenSymbol?: string;
  dollarBalance?: number | undefined;
  tokenBalance: number;
  valueType?: string;
}
export interface WalletTokenWithPercentage {
  token: string;
  percentage: string;
  color: string;
}
export interface PieChartDataInterface {
  id: string;
  label: string;
  value: string;
  color: string;
  actualValue: string;
}
export type TokenInfoArray = TokenInfo[];

export type TokenDataArray = TokenData[];

type State = {
  walletList: {
    total: number;
    wallets: TokenDataArray;
  };
  networkList: any[];
  withdrawHistory: any[];
  transferHistory: any[];
  withdrawAndTransferHistory?: TWithdrawAndTransferHistory;
  swapHistory?: TSwapHistory;
  specialSwapHistory?: any;
  combinedSwapHistory?: any;
  depositHistory?: TDepositHistory;
  trxSettings: any;
  allTokens: any[];
  withdrawFaqs: any;
  transferFaqs: any;
  swapFaqs: any;
  specialSwapFaqs: any;
  depositFaqs: any;
  depositAddress: any;
  withdrawSettings: any;
  swapSettings: any;
  specialSwapSettings: any;
  depositSettings: any;
  loading: boolean;
  graphLoading: boolean;
  walletGraphData: any;
  setWalletDataWithdrawAndTransferHistoryLoading?: boolean;
  getWalletDataDepositHistoryLoading?: boolean;
  getWalletDataSwapHistoryLoading?: boolean;
  qrCodeLoader: boolean;
  getWalletDataLoading: boolean;
};

const initialState: State = {
  walletList: {
    total: 0,
    wallets: [
      {
        balance: 0,
        percentage: '0',
        token: {
          name: 'LYK-W',
          symbol: 'LYK-W',
          color: '#F7931A',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/lyk.png',
          networkList: [
            {
              _id: '66a377f974990141d5c6b9d0',
              name: 'MATIC',
              code: 'matic',
              createdAt: '2024-07-26T10:18:33.051Z',
              updatedAt: '2024-07-26T10:18:33.051Z',
            },
          ],
        },
        _id: '66a3790308364f859796bd8f',
      },
      {
        balance: 0,
        percentage: '0',
        token: {
          name: 'mLYK',
          symbol: 'mLYK',
          color: '#3B89FF',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/mlyk.png',
          networkList: [
            {
              _id: '66a377f974990141d5c6b9d0',
              name: 'MATIC',
              code: 'matic',
              createdAt: '2024-07-26T10:18:33.051Z',
              updatedAt: '2024-07-26T10:18:33.051Z',
            },
          ],
        },
        _id: '66a3790308364f859796bd9b',
      },
      {
        balance: 0,
        percentage: '0',
        token: {
          name: 'sLYK',
          symbol: 'sLYK',
          color: '#53AE94',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/slyk.png',
          networkList: [
            {
              _id: '66a377f974990141d5c6b9d0',
              name: 'MATIC',
              code: 'matic',
              createdAt: '2024-07-26T10:18:33.051Z',
              updatedAt: '2024-07-26T10:18:33.051Z',
            },
          ],
        },
        _id: '66a38986a3043a6c8493d432',
      },
      {
        balance: 0,
        percentage: '0',
        token: {
          name: 'LYK',
          symbol: 'LYK',
          color: '#F7931C',
          iconUrl:
            'https://storage.googleapis.com/softbuilder/homnifi/img/lyk.png',
          networkList: [
            {
              _id: '66a377f974990141d5c6b9d0',
              name: 'MATIC',
              code: 'matic',
              createdAt: '2024-07-26T10:18:33.051Z',
              updatedAt: '2024-07-26T10:18:33.051Z',
            },
          ],
        },
        _id: '66a3cc091f75ac2ad5913c9f',
      },
    ],
  },
  getWalletDataLoading: false,
  networkList: [],
  withdrawHistory: [],
  transferHistory: [],
  trxSettings: null,
  allTokens: [],
  withdrawFaqs: null,
  transferFaqs: null,
  swapFaqs: null,
  specialSwapFaqs: null,
  depositFaqs: null,
  depositAddress: null,
  withdrawSettings: null,
  swapSettings: null,
  specialSwapSettings: null,
  depositSettings: null,
  loading: false,
  graphLoading: false,
  walletGraphData: [],
  setWalletDataWithdrawAndTransferHistoryLoading: false,
  getWalletDataDepositHistoryLoading: false,
  getWalletDataSwapHistoryLoading: false,
  qrCodeLoader: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setWalletDataWalletsList: (state: State, action: PayloadAction<any>) => {
      return { ...state, walletList: action.payload };
    },
    setWalletDataNetworksList: (state: State, action: PayloadAction<any>) => {
      return { ...state, networkList: action.payload };
    },
    setWalletDataWithdrawHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, withdrawHistory: action.payload };
    },
    setWalletDataTransferHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, transferHistory: action.payload };
    },
    setWalletDataWithdrawAndTransferHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, withdrawAndTransferHistory: action.payload };
    },
    setWalletDataSwapHistory: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapHistory: action.payload };
    },
    setWalletDataSpecialSwapHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, specialSwapHistory: action.payload };
    },
    setWalletDataCombinedSwapHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, combinedSwapHistory: action.payload };
    },
    setWalletDataDepositHistory: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositHistory: action.payload };
    },
    setWalletDataTrxSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, trxSettings: action.payload };
    },
    setWalletDataAllTokens: (state: State, action: PayloadAction<any>) => {
      return { ...state, allTokens: action.payload };
    },
    setWalletDataWithdrawFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, withdrawFaqs: action.payload };
    },
    setWalletDataTransferFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, transferFaqs: action.payload };
    },
    setWalletDataSwapFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapFaqs: action.payload };
    },
    setWalletDataSpecialSwapFaqs: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, specialSwapFaqs: action.payload };
    },
    setWalletDataDepositFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositFaqs: action.payload };
    },
    setDepositAddress: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositAddress: action.payload };
    },
    setQRLoader: (state: State, action: PayloadAction<any>) => {
      return { ...state, qrCodeLoader: action.payload };
    },
    setWithdrawSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, withdrawSettings: action.payload };
    },
    setSwapSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapSettings: action.payload };
    },
    setSpecialSwapSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, specialSwapSettings: action.payload };
    },
    setDepositSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositSettings: action.payload };
    },
    setLoading: (state: State, action: PayloadAction<any>) => {
      return { ...state, loading: action.payload };
    },
    setWalletDataSlice: (state: State, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const WALLETDATA_SLICE_NAME = name;
export const {
  setWalletDataWalletsList,
  setWalletDataNetworksList,
  setWalletDataWithdrawHistory,
  setWalletDataTransferHistory,
  setWalletDataWithdrawAndTransferHistory,
  setWalletDataSwapHistory,
  setWalletDataSpecialSwapHistory,
  setWalletDataCombinedSwapHistory,
  setWalletDataDepositHistory,
  setWalletDataTrxSettings,
  setWalletDataAllTokens,
  setWalletDataWithdrawFaqs,
  setWalletDataTransferFaqs,
  setWalletDataSwapFaqs,
  setWalletDataSpecialSwapFaqs,
  setWalletDataDepositFaqs,
  setDepositAddress,
  setWithdrawSettings,
  setSwapSettings,
  setSpecialSwapSettings,
  setDepositSettings,
  setWalletDataSlice,
  setLoading,
  setQRLoader,
} = slice.actions;
export const selectWalletData = (state: RootState) =>
  state[WALLETDATA_SLICE_NAME];
export default slice.reducer;
